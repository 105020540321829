.App .MuiInputBase-root{
    padding: 10.5px 10px !important;
}
.dropdown-content {
    background-color: aliceblue;
    position: relative;
  }
  
  .rmsc .dropdown-content {
    z-index: 999 !important;
  }

a {
    text-decoration: none;
} 

/* App.css */

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: auto;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 50vh;
}

.h-50{
  height: 50vh;
}

.editor-content {
  padding: 10px;
  height: 300px; /* Adjust height as needed */
  overflow-y: auto;
}

.mainJobdiv{
  margin-top: 6em;
}

.formStyleMain{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}
.activeclass {
  display: flex;
  width: 10em;
  white-space: nowrap;
  align-items: center;
}

.p-1{
  padding: 1rem;
}

.jobTitle{
  display: flex;
  width: 100%;
  justify-content: center;
}
.h-10{
  height: 10em;
}

.oswn{
  overflow: scroll;
    white-space: nowrap;
}
